$white: #fff;
$labelcolor: #bdbdc7;
$primary_color: #04533a;
$bgcolor: #0b2b44;
$topnav: #4d8ab9;
$sidebar: #1a568494;
.authincation {
    height: 100vh !important;
    display: flex;
    align-items: center;
    background: url("../assets/images/bgimage.jpg");
    background-size: cover;
    justify-content: flex-end;
    .loginpage {
        position:relative;
       
        width: 100%;
        min-height: 100vh;
        background: #fff;
        max-width: 550px;
        // background: url(../img/bgpattern.ea1d6d8c.png) top no-repeat #fff;
        background-size: 100% auto;
        text-align: center;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
        color: #424242;
        font-size: 12px;
        position: relative;
        display: flex;
        flex-direction: column;
        padding:20px;
        z-index: 1;
        &:before{
            content:"";
            background:url('../assets/images/login_bg.png');
            position: absolute;
            top:0px;
            left:0px;
            width:100%;
            height:100%;
            z-index: -1;
            opacity: 0.6;
        }
        h2 {
            font-size: 14px;
            font-weight: 700;
            margin-bottom: 40px;
            margin-top: 0;
            color: #6ba353;
        }
        h3{
            color: #6ba353;
            font-size: 16px;
            text-transform: capitalize;
        }
        .icon_number{
            width: 40px;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 18px;
            background: #f7f7f7;
            border-right: 1px solid #a4a4a4;
            cursor: default;
        }
        .logo {
            width: 300px;
            margin: 24px auto;
            img {
                width: 100%;
            }
        }
        .loginnmumber{
            border: 1px solid #a4a4a4;
            text-align: left;
            overflow: hidden;
            border-radius: 4px;
        }
        .inputlogin{
            border-radius:0px;
            background:#f7f7f7;
            border:0px;
            
        }
        .inputlogin::-webkit-inner-spin-button,
        .inputlogin::-webkit-outer-spin-button{
            appearance: none;
        }
        .btn_custum,
        .btn_custum:active,
        .btn_custum:hover{
            background:$primary_color;
            color:#fff;
            margin: 20px 0;
            padding: 12px;
        }
        .contactno{
            margin-top:20px;
     width:150px;
     margin:auto;
            a{
                color:#000;
                  img{
                    width:100%;
                  }
            }

        }
    }

    .contactMessage{
        margin-top:10px;
 width:100%;
//  margin:auto;
      padding: 15px;  
align-items: center;
background: #d4b858;
    }
.forgetpsw {
    color: #bdbdc7;
}
.authincation-content {
    background: #fff;
    box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15);
    border-radius: 5px;
}
.auth-form {
    padding: 50px 50px;
    .btn {
        height: 50px;
        font-weight: 700;
        background-color: #593bdb;
        border-color: #593bdb;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
}
.btn_custum{
    background-color: #04533a;
}