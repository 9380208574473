.custom-modal {
    position: fixed;
    left: 50%;
    right: 0px;
    bottom: 0px;
    top: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translate(-50%, -50%);
    z-index: 999999;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background: #0000007a;
}
.bellicon {
    svg{
        font-size:40px;
    }
}
.overlay_modal{
    position: fixed;
    left: 50%;
    right: 0px;
    bottom: 0px;
    top: 50%;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    transform: translate(-50%, -50%);
    z-index: -1;
}
.modal_width {
    position: relative;
    width: 60%;
    padding: 1rem;
    border-radius: 3px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}
.toheading_bg{
    background:#fd1f02;
    color:#fff;
    h3{
        font-size: 16px;
        padding: 10px;
    }
}
.close_btn {
    width: 40px;
    height: 40px;
    font-size: 44px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #04533a;
    border-radius: 50%;
    position: absolute;
    right: 8px;
    top: 50px;
}
.modal_header{
    width: 100%;

    background: #04533a;
    padding: 7px;
    position:relative;
 h3{
    font-size: 16px;
    color: #fff;
 }
}
.modal_body img {
    width: 100%;
    object-fit: cover;
    height: 400px;
}
@media(max-width:991px){
    .modal_width {
        width: 100%;
    }
    .modal_body img {
        width: 100%;
        object-fit: cover;
        height: 315px;
    }
    .toheading_bg h3 {
        font-size: 12px;
        padding: 7px;
    }
    .bellicon svg {
        font-size: 40px;
    }
}
.countnotification{
    position: absolute;
    top: 0px;
    right: 0px;
}