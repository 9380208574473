$white: #fff;
$labelcolor: #bdbdc7;
$primary_color: #1a5684;
$bgcolor: #23292e;
$topnav: #444;
$sidebar: #1a568494;
$universal:#04533a;
$bgcard:#444;
.menu li a{
    padding: 6px 8px;
    span {
        display: block;
        font-size: 14px;

    }
}
.overlay_screen.open{
    display: none;
}
.overlay_screen.close{
    display: block;
}
.overlay_screen{
position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 992;
    display: block;
}

.sidebar{
        z-index: 9999;
    max-width: 220px;
    width: 100%;
    background: #0b0f12;
    height: 100vh;
    left: 0px;
    top: 40px;
    position: fixed;
    cursor: pointer;
    user-select: none;
    .heading_game{
        color: rgb(255 255 255);
        font-size: 16px;
        font-weight: bold;
        padding: 7px;
        background: $universal;
    }
 .menusidebar ul li .menubar_series{
    font-size: 14px;
    color: #fff;
    align-items: center;
    span{
        padding:6px 8px;
        display:block;
    }
    &.active{
        background:#2fc193;
    }

}
.submenu::-webkit-scrollbar{
    width: 10px;
    background: red;
}
.submenu::-webkit-scrollbar {
    width: 5px;
    height: 8px;
    background-color: #aaa;
  }
 .submenu::-webkit-scrollbar-thumb {
    background: #000;
  }
.submenu::-webkit-scrollbar-button{
    width: 10px;
    background: #23292e;
}
.submenu{
    background:$topnav;
    height: 300px;
    overflow-y: scroll;
    overflow-x: hidden;

   .submenu-item{
    color: #fff;
    align-items: center;
    font-size:12px;
    .submenubar_series{
        span{
           padding:6px 8px;
           display:block; 
        }
        
    }
  
    .submenubar_series.active{
        span{
          background:#347963;
        }
        
    }
    .submenu{
        li{
            padding:6px 8px;
        }
    }
    &.active{
        background:#04533a;      
    }
   }
}
.logo_web{
    width: 150px;
    margin: auto;
    padding: 10px;
    img{
        width:100%;
    }
}
}


@media(max-width:1025px){
    .mobilesidebar{
        display:none;
    }
    .mobilesidebarmenu{
        display:block;
    }
    .closebtn_toggle{
        position: absolute;
        left: 217px;
        top: 0px;
        z-index: 999;
        font-size: 24px;
        background: #0b0f12;
        padding: 3px;
    }
}
@media(max-width:991px){
    .mobilesidebar{
        display:none;
    }
    .mobilesidebarmenu{
        display:block;
    }
    .closebtn_toggle{
        position: absolute;
        left: 217px;
        top: 0px;
        z-index: 999;
        font-size: 24px;
        background: #0b0f12;
        padding: 3px;
    }
}