$white: #fff;
$labelcolor: #bdbdc7;
$primary_color: #1a5684;
$bgcolor: #23292e;
$topnav: #444;
$sidebar: #1a568494;
$universal: #04533a;
$bgcard: #444;
$textcolor: #aaafb5;
$black: #000;
.elementname {
  font-size: 12px;
}
.whatsappicon {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 40px;
    right: 17px;
    margin-bottom: 76px;
    background-color: #25d366;
    color: #fff;
    border-radius: 50px;
    text-align: center;
    font-size: 15px;
    box-shadow: 2px 2px 3px #999;
    z-index: 100;
  a {
    img {
      width: 100%;
      height: 100%;
    }
  }
}
.offbookmakerKey {
  position: relative;
  &:before {
    width: 100%;
    height: 99%;
    background-color: #000000bd;
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 99;
  }
}
// .faluate0{
//     position: relative;
//     overflow: hidden;
//     z-index: 1;
//     &:before{
//     content: "";
//         position: absolute;
//         left: -5px;
//         top: -12px;
//         width: 61px;
//         height: 60px;
//         z-index: -1;
//         animation: popupflip 1s infinite;
//         border-radius: 50%;
//     }

// }
// @keyframes popupflip {
//     100% {
//           background-color: yellow;
//           transform: scale(1);
//         }

// }
// .faluatesec0{
//     position: relative;
//     overflow: hidden;
//     z-index: 1;
//     &:after{
//     content: "";
//         position: absolute;
//         left: -5px;
//         top: -12px;
//         width: 61px;
//         height: 60px;
//         z-index: -1;
//         animation: popupflip 1s infinite;
//         border-radius: 50%;
//     }

// }
// @keyframes popupflip {
//     100% {
//           background-color: brown;
//           transform: scale(1);
//         }

// }
.pagination {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 10px;

  button {
    border: 0px;
    outline: none;
    background-color: #0fe6a2;
    color: white;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-top: 13px;
  }
}
/* Popup background */
.popup.new_poup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  transition: 0.5s linear all ease-in-out;
}

/* Hidden class for hiding the popup */
.hidden {
  display: none;
}
.mobile_d-none-online {
  display: block;
}
.mobile_show_tv {
  display: none;
}
.overlflowscrollpopupbettting {
  overflow-y: scroll;
  max-height: 90vh;
  overflow-x: hidden;
  height: 100%;
}
.headingallbet {
  width: 100%;
  padding: 5px 6px;
  font-size: 13px;
  color: #fff;
  background: #04533a;
}
/* Popup content styling */
.popup-content-sec {
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 98%;
  margin: 15px auto 0px;
  height: 100%;
}

.popup-content-sec h2 {
  margin-top: 0;
}
.padding_10 {
  padding: 10px;
}
.bg_green_custum {
  background-color: $universal !important;
}
.bg-color-sec {
  background-color: $textcolor;
}
.border-radius {
  border-radius: 0px !important;
}
.height_all_new.betplacecolor.lay {
  height: 18px;
}
/* Base styles for terms-co10pxntainer */
.terms-container {
  background-color: #444444;
  color: #ffffff;
  padding: 20px;
  font-family: Arial, sans-serif;
  max-width: 900px;
  margin: 20px auto;
  border-radius: 8px;
}
.btn-custumtb {
  background-color: $universal;
  padding: 3px 10px;
  color: $white;
  border: 1px solid $white;
  font-size: 13px;
  border-radius: 10px;
}
/* Heading styles */
.terms-heading {
  font-size: 28px;
  line-height: 1.4;
  margin-bottom: 20px;
  border-bottom: 2px solid #ffffff;
  padding-bottom: 10px;
}

/* Section styles */
.terms-section {
  margin-bottom: 20px;
}

.terms-section h2 {
  font-size: 22px;
  margin-bottom: 10px;
  border-bottom: 1px solid #ffffff;
  padding-bottom: 5px;
}

.terms-paragraph,
.terms-section p {
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 10px;
}

.matchdate {
  font-size: 13px;
}
.matchname {
  // font-size:12px;
  // height:50px;
  // display: flex;
  // align-items: center;
  // overflow: hidden;
}
.suspended.normalsuspand {
  right: 69px;
  width: 108px !important;
}
.footer {
  margin-top: 0;
  padding: 8px 0;
  width: 100%;
  display: flex;
  display: -webkit-flex;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
}

.footer-logo {
  width: 10%;
  display: flex;
  display: -webkit-flex;
  justify-content: center;
  align-items: center;
}

.footer-logo img {
  max-width: 100%;
  max-height: 50px;
}

.footer-box {
  width: 90%;
  margin: 20px auto 0;
}

.footer ul {
  display: inline-block;
}

.footer ul li {
  float: left;
}

.footer ul li a {
  height: 22px;
  color: var(--text-body);
}

.footer-social img {
  margin-left: 5px;
  height: 30px;
}

.footer-content {
  margin-top: 24px;
}

.footer-content p {
  color: var(--text-body);
  font-size: var(--font-caption);
  line-height: 19px;
}

.footer-faq {
  padding: 20px 20px 20px 0;
  width: 98%;
  margin: 0 auto;
  margin-bottom: 10px;
}

.faq-content {
  border-top: 0;
}

.home-new .footer-faq .sport-list-title {
  margin-bottom: 0;
  display: block;
  margin-left: 0;
}

.footer-top {
  display: flex;
  display: -webkit-flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px dashed var(--text-body);
  padding-bottom: 6px;
}

.footer-top .secure-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: normal;
}

.footer-top .secure-logo img {
  max-height: 50px;
}

.footer .support {
  width: 100%;
  display: flex;
  background-color: #444;
  padding: 10px;
  justify-content: center;
  color: #ddd;
  border-radius: 0px;
  position: relative;
  flex-wrap: wrap;
}

.footer .support > div:first-child {
  flex: auto;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
}

.footer .support > div:first-child b {
  font-size: 20px;
}

.footer-top img {
  max-height: 30px;
  max-width: 100px;
  margin-right: 10px;
}

.footer .footer-social {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
}

.footer-other {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.footer-bottom {
  color: var(--text-body);
  font-size: var(--font-caption);
  line-height: 19px;
  margin-top: 10px;
  text-align: center;
}
.footer-top {
  display: flex;
  display: -webkit-flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px dashed #aaafb5;
  padding-bottom: 6px;
}
.copyright,
.secure {
  font-size: 13px;
  color: #aaafb5;
}
.footer-menu {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 5px;
  justify-content: center;
  margin-top: 7px;
}

.footer-menu ul li {
  margin: 0 8px;
  padding: 0 16px;
  position: relative;
}

.footer-menu ul li:first-child {
  margin-left: 0;
  padding-left: 0;
}

.footer-menu ul li:last-child {
  margin-right: 0;
  padding-right: 0;
}

.footer-menu ul li::after {
  position: absolute;
  content: "";
  background-color: var(--text-body);
  height: 8px;
  width: 8px;
  border-radius: 8px;
  top: 7px;
  right: -16px;
}

.footer-menu ul li:last-child::after {
  background-color: transparent;
}

.footer-menu ul li a {
  color: #aaafb5;
  font-weight: bold;
  font-size: 14px;
  transition: 0.5s;
  border-bottom: 1px solid #aaafb5;
}

.footer-menu ul li a:hover {
  text-decoration: underline;
}

// live casino css
.gameimage {
  width: 100%;
  margin-top: 10px;
  img {
    width: 100%;
  }
}
// live casino css
// .width_image{
//     font-size:13px;
//     span{
//         display:block;
//         font-size:11px;

//     }
// }
// .fixure-box-container{
//     width:300px;
//     // .width_image{
//     //     white-space:nowrap;
//     //     span{
//     //         display:block;
//     //     }
//     // }
//     .vertical-slider-container {
//         width: 100%;
//         position: relative;
//       }
//     .slick-slide {
//         height: 100%;
//       }

//       .slick-track {
//         display: flex !important;
//         flex-direction: column;
//       }

//       .slick-slide > div {
//         height: 100%;
//         display: flex;
//         align-items: center;
//         justify-content: center;
//       }

//       .slick-dots {
//         bottom: 10px;
//       }
// }
// broadcast css start

.content {
  font-size: 2rem;
  font-weight: bold;
  color: #333; /* Adjust color as needed */
  position: absolute;
  bottom: -100%; /* Start off-screen */
  animation: slideUp 1s ease-out forwards;
}
.carousel-container {
  position: relative;
  height: 300px; /* Set height for vertical scrolling */
}

.owl-nav {
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: center;
  top: 10px; /* Position arrows at the top */
}

.owl-nav .owl-prev,
.owl-nav .owl-next {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  color: white;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  z-index: 1000; /* Ensure arrows are above other elements */
}

.owl-nav .owl-prev {
  top: 10px;
  left: 10px; /* Position left arrow */
}

.owl-nav .owl-next {
  top: 10px;
  right: 10px; /* Position right arrow */
}

.owl-nav.bottom {
  top: auto;
  bottom: 10px; /* Position arrows at the bottom */
}

.item {
  text-align: center;
  padding: 20px;
  background: #f5f5f5;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.owl-dots {
  display: none; /* Hide dots if not needed */
}
@keyframes slideUp {
  from {
    bottom: -100%; /* Start from below */
    opacity: 0;
  }
  to {
    bottom: 20px; /* Adjust this value to control final position */
    opacity: 1;
  }
}
.upcoming-fixure.heading_new {
  display: flex !important;
  flex-wrap: nowrap !important;
  width: 33% !important;
  position: relative;
}
.Broadcast {
  .Notification_design {
    background: $bgcard;
    padding: 15px;
    border-radius: 8px;
    border-left: 8px solid $universal;
    border-right: 2px solid $universal;
    border-bottom: 2px solid $universal;
    border-top: 2px solid $universal;
    margin-top: 10px;
  }
  .image_user {
    width: 250px;
    img {
      width: 100%;
    }
  }
  .notification_heading {
    .heading {
      background: $universal;
      padding: 10px;
      border-radius: 5px;
    }
    .datetime {
      margin-top: 10px;
      .date {
        margin-right: 5px;
      }
    }
  }
}
// broadcast css end
.btn-amount {
  border-radius: 5px;
  width: 145px;
  box-shadow: none;
  border: 0px;
  padding: 7px;
  color: #fff;
  border-radius: 5px;
}
.form-custum.form-control::placeholder {
  color: #aaafb5;
}
.form-custum.form-control:hover,
.form-custum.form-control:active,
.form-custum.form-control:focus,
.form-custum.form-control {
  display: inline-block;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  height: 36px;
  padding: 6px 30px;
  padding-left: 20px;
  font-size: 14px;
  line-height: 1.4;
  color: #aaafb5;
  background-color: transparent;
  border: 1px solid #aaafb5;
  color: #aaafb5 !important;
  border-radius: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
select {
  background: url("../src/assets/images/arrow-down.svg") center right 10px
    no-repeat;
  -moz-appearance: none;
  -webkit-appearance: none;
}
.margin-top-40 {
  margin-top: 40px;
}
.padding_15 {
  padding: 15px;
}
.rupeeicon {
  position: absolute;
  left: 13px;
  top: 6px;
}
.padding-15-leftright {
  padding: 0px 30px !important;
}
.logo_design {
  width: 40%;
  margin-right: 30px;
  padding: 5px;
  margin-top: -8px;
  a {
    width: 122px;
    display: block;
    img {
      width: 100%;
    }
  }
}
.margin-top-bottom-20 {
  margin-top: 20px;
  margin-bottom: 20px;
}
.account_statement {
  .btn_custom {
    width: 150px;
    background-color: $universal;
    color: #fff;
    border: 0px;
    outline: none;
    text-align: center;
    display: flex;
    justify-content: center;
  }
  .form-custum::-webkit-calendar-picker-indicator {
    color: #ddd;
  }
  .form-select {
    background-color: #444;
    color: #ddd;
  }

  .form-select {
    height: 36px;
    border-radius: 0;
    border: 1px solid #aaafb5;
    color: #ddd;
    background: #444444;
  }

  .otherpagegap {
  }
  label {
    font-size: 12px;
    color: #aaafb5;
    margin-bottom: 8px;
  }
  .form-custum {
    background: transparent;
    box-sizing: border-box;
    width: 100%;
    height: 36px;
    padding: 6px 30px;
    padding-left: 10px;
    font-size: 14px;
    line-height: 1.4;
    color: #fff;
    background-color: transparent;
    border: 1px solid #aaafb5;
    color: #aaafb5;
    border-radius: 0;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  }
}
body {
  user-select: none;
  background: $bgcolor !important;
  margin: 0;
  font-family: "Roboto", sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #bdbdc7;
  text-align: left;
  ul {
    list-style: none;
    padding-left: 0px;
  }
}
input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button {
  appearance: none !important;
  margin: 0;
}

.accountstmt {
  thead {
    th {
      background-color: rgba(14, 78, 49, 0.729) !important;
      color: #fff;
      font-size: 15px;
      white-space: nowrap;
      padding: 9px;
    }
  }
  tbody {
    tr {
      th {
        font-size: 14px;
      }
    }
  }
}
.table-sm > :not(caption) > * > * {
  padding: 0.25rem;
  font-size: 12px;
}

.playtv {
  background: #444444;
  padding: 3px 5px;
  font-size: 12px;
  display: flex;
  align-items: center;
  line-height: 1.5;
  border-radius: 5px;
  margin: 5px 0px;
}
.dropdown_profile.activenav {
  transform: translateX(-0px);
  opacity: 1;
}
.dropdown_profile {
  background: #fff;
  width: 180px;
  position: absolute;
  right: 0;
  top: 100%;
  border-radius: 4px;
  background-color: #111;
  z-index: 10;
  border-radius: 5px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.57);
  text-align: left;
  border-radius: 0;
  color: #fff;
  ul {
    margin-bottom: 0px;
    li a {
      display: block;
      width: 170px;
      color: #eee;
      opacity: 0.9;
      padding-left: 0;
      text-decoration: none;
      font-size: 14px;
      margin-top: 6px;
      margin-bottom: 6px;
      padding-left: 16px;
      line-height: 22px;
      padding-top: 6px;
      padding-bottom: 6px;
    }
  }
}
.videoheight {
  padding-top: 40.25% !important;
}
.bet-slip-title {
  color: rgb(170 175 181);
  font-weight: bold;
  text-transform: uppercase;
  display: inline-block;
  margin-bottom: 0.5rem;
  margin-top: 15px;
  font-size: 14px;
}
.inputbetting {
  background: #2e3439 !important;
  border: 0px !important;
  border-radius: 1px !important;
  height: 44px !important;
  color: #fff !important;
  width: 95% !important;
}
// betting_page start
.width_min.bettingwidth {
  width: calc(100% - 222px) !important;
}
.border-bottom-1 {
  border-bottom: 1px solid #cccccc52;
}
.suspended {
  position: absolute;
  right: 0px;
  width: 111px   !important;
  height: 100%;
  background: rgb(0 0 0 / 70%);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  color: red;
}
.suspended.matchods {
  position: absolute;
  right: 0px;
  width: 100% !important;
  height: 100%;
  background: rgb(0 0 0 / 70%);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  color: red;

  z-index: 99;
  border-bottom: 1px solid #cccccc59;
  border-top: 1px solid #cccccc4d;
}
.betting_page {
  .round_id {
    padding: 7px;
    margin-bottom: 6px;
    background: $topnav;
    color: #fff;
    h3 {
      font-size: 15px;
    }
  }
  .buttonacrrodian {
    background: $universal;
    padding: 10px 20px;
    font-size: 13px;
  }
  .bet_details_all {
    padding: 5px 0px;
    .width_min {
      width: calc(100% - 332px);
      padding-right: 5px;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      align-items: center;
      position: relative;
      font-size: 13px;
      color: #ccc;
    }
    .width_72 {
      width: 72px;
    }
    .back {
      border-radius: 0;
      width: 52px;
      text-align: center;
      line-height: 22px;
      color: #fff;
      font-weight: var(--font-medium);
      margin-right: 4px;
      cursor: default;
      background: #72bbef;
    }
    .lay {
      border-radius: 0;
      width: 52px;
      text-align: center;
      line-height: 22px;
      color: #fff;
      font-weight: var(--font-medium);
      margin-right: 4px;
      cursor: default;
      background: #f994ba;
    }
    .backbet_design {
      background: #72bbef;
    }
    .laybet_design {
      background: #f994ba;
    }

    .headinglay {
      flex-wrap: wrap;
      width: 100%;
      margin: 3px 5px;
      align-items: center;
      border-bottom: 1px solid #ffffff3b;
      padding-bottom: 3px;
      .width_min.width_adjust {
        width: calc(100% - 195px);
      }

      .minmax {
        font-size: 10px;
        color: #f1f1f1;
        span {
          display: block;
        }
      }
    }
  }
}
.space,
.backbet_design,
.laybet_design {
  width: 52px;
  text-align: center;
  font-size: 16px;
  border-radius: 2px;
  margin-right: 3px;
  font-weight: bold;
  line-height: 20px;
  margin-bottom: 1px;
  color: #000;

  span {
    display: block;
    font-size: 13px;
  }
  span:nth-child(2) {
    font-size: 9px;
    line-height: 1.4;
  }
}
.bettingforall {
  margin-bottom: 5px;
}
.bettingforall.normal {
  .bl-box {
    width: 52px;
    height: 38px;
    padding: 3px 0;
    display: flex;
    display: -webkit-flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    margin-right: 4px;
    float: left;
    cursor: pointer;
    position: relative;
    &:before {
      content: "";
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 4px;
    }
    span {
      height: 14px;
      line-height: 1.5;
      font-size: 12px;
      color: #000;
    }
    span:nth-child(2) {
      font-size: 12px;
      line-height: 1.4;
    }
    .odds {
      height: 13px;
      line-height: 16px;
      margin-bottom: 1px;
      color: black;
      font-weight: bold;
      text-align: center;
      font-size: 13px;
    }
    .fancy-min-max {
      font-size: 12px;
      text-align: right;
      line-height: 16px;
      font-weight: bold;
      color: #aaafb5;
    }
  }
  .nation-name p {
    height: 19px;
    color: #aaafb5;
    margin-bottom: 6px;
    margin-bottom: 0;
    line-height: 20px;
    width: 100%;
    font-size: 13px;
  }
  .fancy-tripple {
    border-bottom: 1px solid #3c444b;
    .d-none-desktop {
      display: none !important;
    }
    .bet-table-row {
      border-bottom: 0;
    }

    .fancy-min-max {
      width: 52px;
      font-size: 12px;
      text-align: right;
      line-height: 16px;
      font-weight: bold;
      display: flex;
      flex-wrap: wrap;
      color: #aaafb5;
    }
  }
  .bet-table-row {
    display: -webkit-flex;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
  }
  // .row.row5 {
  //     margin-left: -5px;
  //     margin-right: -5px;
  // }
  .fancy-tripple {
    // height: 100%;
  }
  .nation-name {
    width: calc(100% - 164px);
  }
  .detail-page-container .bl-title {
    width: 52px;
  }
  .detail-page-container .bl-title {
    height: 22px;
    border-radius: 0;
    width: 72px;
    text-align: center;
    line-height: 22px;
    color: var(--text-black);
    font-weight: var(--font-medium);
    margin-right: 4px;
    cursor: default;
    font-size: var(--font-caption);
  }
}
.placeingbet {
  // background:$universal;
  .headingallbet {
    h4 {
      color: $textcolor;
      font-size: 15px;
      margin-bottom: 0.5rem;
    }
  }
  .matchname {
    background: $bgcard;
    padding: 5px 6px;
    .matchnamelist {
      p {
        font-size: 13px;
        color: $black;
        span {
          font-size: 14px;
          display: block;
        }
      }
    }
    .borderbottomscore {
      border-bottom: 1px solid #f1f1f1;
      margin-bottom: 5px;
      p {
        color: $black;
      }
    }
    .Profit {
      background: $universal;
      padding: 3px 23px;
      border-radius: 5px;
      p {
        color: $white;
        font-size: 13px;
        line-height: 1;
      }
      span {
        color: $white;
        font-size: 13px;
      }
    }
    button.btn.amountbtn {
      background: $universal;
      min-width: calc(25% - 4px);
      margin-right: 4px;
      margin-bottom: 4px;
      height: 40px;
      border-radius: 0;
      border-color: transparent;
      padding: 0;
      color: #fff;
    }
    .placebet_design {
      .placebet {
        background: $universal;
        color: #fff;
      }
    }
  }
}
.betting_input {
  margin-top: 10px;
  margin-bottom: 15px;
}
// betting_page ned
.betting_model {
  color: $white;
  .match_list_name {
    border-bottom: 1px solid #fff;
  }
  .modal-header {
    padding: 10px;
    .modal-title {
      font-size: 15px;
    }
  }
  button.btn.amountbtn {
    width: 85px;
    background: $universal;
    margin: 5px;
    color: #fff;
  }
  .Profit {
    p {
      margin-bottom: 0px;
      padding-bottom: 0px;
    }
  }
  .placebet_design {
    .placebet {
      background: $universal;
      color: #fff;
    }
  }
  .modal-content {
    background-color: $topnav;
    .modal-body {
      padding: 10px;
    }
  }
  .decrement,
  .increment {
    background: $bgcolor;
    color: #fff;
    border-radius: 0px;
  }
  .profitcount {
    border-radius: 0px;
    background: #1a5684a3;
    border: 0px;
    &:active,
    &:hover,
    &:focus {
      box-shadow: none;
      outline: none;
    }
  }
  .teamname {
    color: $black;
    margin-bottom: 0px;
    font-size: 13px;
    line-height: 1.2;
  }
  .matchname_list {
    h6 {
      margin-bottom: 0px;
      color: $black;
      margin-top: 5px;
    }
    p {
      color: $black;
    }
  }
  .betting_input {
    margin-top: 5px;
    margin-bottom: 15px;
    .inputbetting {
      background: #1a5684;
      font-size: 15px;
      color: #fff;
      padding: 2px 6px;
    }
  }
}
.sliderbanner {
  .width_image {
    img {
      width: 100%;
    }
  }
  .slick-dots {
    bottom: 0px;
  }
}
.btn-primary {
  color: #fff;
  background-color: #593bdb;
  border-color: #593bdb;
}
body {
  font-family: "Roboto", sans-serif !important;
}
.form-group {
  margin-bottom: 1rem;
}
.auth-form .btn {
  height: 50px;
  font-weight: 700;
}
input:hover,
input:active,
input:focus-within,
input:focus {
  outline: none;
  box-shadow: none !important;
  border-color: #b4a7ef;
  outline: 0;
}
a,
a:hover,
a:focus,
a:active {
  text-decoration: none !important;
}

.form_label_new {
  color: $labelcolor;
  font-weight: 500;
}
.matchtv {
  position: relative;
  .closetv {
    position: absolute;
    content: "";
    right: 0px;
    top: 5px;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      font-size: 42px;
      color: #fff;
    }
  }
}

.betting_section {
  width: calc(100% - 300px);
}
.placebet_section {
  width: 384px;
  .my_bet {
    width: 100%;
    padding: 5px 6px;
    font-size: 13px;
    color: #fff;
    background: $universal;
  }
  .betname {
    font-size: 13px;
    color: $textcolor;
    width: 53%;
  }
  .betplacecolor {
    width: 5px;
    height: 40px;
  }
  .amountbet,
  .runinbet {
    color: $textcolor;
    font-size: 13px;
  }
}
// index css start
.header-top {
  position: fixed;
  top: 0;
  height: 40px;
  width: 100%;
  display: flex;
  display: -webkit-flex;
  justify-content: center;
  align-items: center;
  background-color: #04533a;
  z-index: 100;
  ul {
    display: flex;
    display: -webkit-flex;
    list-style: none;
    margin-bottom: 0px;
    li {
      margin: 0 16px;
      padding: 0 16px;
      position: relative;

      &:after {
        position: absolute;
        content: "";
        background-color: $white;
        height: 8px;
        width: 8px;
        border-radius: 8px;
        top: 7px;
        right: -16px;
      }

      a {
        font-size: 14px;
        color: #fff;
        &:active,
        &:focus,
        &:active {
          border: none;
          box-shadow: none;
        }
      }
    }
    li:last-child::after {
      content: none;
    }
  }
}
.content-body {
  margin-left: 220px;
  z-index: 0;
  padding-top: 40px;
  transition: all 0.2s ease;
  color: #fff;
}

// index css end

// Topnavbar css start
.Topnavbar {
  padding: 5px 0px;
  display: flex;
  gap: 10px;
  .btn_Transaction:hover,
  .btn_Transaction {
    background: $universal;
    height: 30px;
    border-radius: 5px;
    color: #fff;
    line-height: 30px;
    display: flex;
    align-items: center;
    margin-left: 5px;
  }
  .fixure-title {
    float: left;
    width: 70px;
    background-color: $universal;
    text-align: center;
    padding: 8px 0;
    border-radius: 0s;
    height: 100%;
    position: relative;
    display: flex;
    display: -webkit-flex;
    justify-content: start;
    align-items: center;
    margin-right: 25px;
    color: #fff;
    font-size: 12px;
    padding-left: 10px;
    line-height: normal;
    &:after {
      content: "";
      position: absolute;
      width: 45px;
      height: 45px;
      border-top: 23px solid transparent;
      border-left: 23px solid $universal;
      border-bottom: 23px solid transparent;
      right: -45px;
      top: 0;
    }
  }
  .upcoming-fixure {
    flex: 1;
    width: 40%;
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    height: 45px;
    background-color: $topnav;
    color: #ddd;
    border-radius: 5px;
  }
  .news-bar {
    border-radius: 5px;
    width: 50%;
    margin: 0;
    padding: 0;
    font-size: var(--font-caption);
    display: flex;
    display: -webkit-flex;
    align-items: center;
    height: 45px;
    flex: 1;
    background-color: $topnav;
    color: #ddd;
    margin-left: 10px;
  }
  .wallet_details {
    cursor: pointer;
    display: flex;
    flex-wrap: wrap;
    background: $topnav;
    width: auto;
    padding: 0 5px 0 0;
    border-radius: 5px;

    height: 45px;
    .icon {
      background: $universal;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
      padding-right: 10px;
      padding-left: 10px;
      color: #ddd;
      height: 45px;
      width: 45px;
      font-size: 30px;
    }
    .pts {
      p {
        font-size: 13px;
        margin-bottom: 0px;
      }
    }
  }
  .loginId {
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    flex-wrap: wrap;
    background: $topnav;
    width: auto;
    padding: 0 5px 0 0;
    height: 45px;
    .icon {
      background: $universal;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
      padding-right: 10px;
      padding-left: 10px;
      color: #ddd;
      height: 45px;
      width: 45px;
      font-size: 30px;
    }
    .pts {
      p {
        font-size: 13px;
        margin-bottom: 0px;
      }
    }
  }
  .speaker {
    width: 45px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
  }
  .shake {
    animation: shake 0.9s infinite;
  }
  @keyframes shake {
    0% {
      transform: scale(0.8);
    }
    50% {
      transform: scale(1);
    }
    100% {
      transform: scale(0.8);
    }
  }
}
// Topnavbar css end

// index page css
.bet-table-header {
  height: 40px;
  padding: 8px;
  border-radius: 0;
  display: flex;
  display: -webkit-flex;
  width: 100%;
  color: $white;
  background: $topnav;
  .game-title {
    width: calc(100% - 315px);
    display: flex;
    display: -webkit-flex;
    flex-wrap: wrap;
    align-items: center;
  }
  .point-title {
    width: 108px;
    text-align: center;
  }
  .bet-table-box {
    border-bottom: 1px solid #3c444b;
  }
}
.bet-table-body {
  background: #2e3439;
  padding: 0px 4px;
  .text-light {
    color: #aaafb5 !important;
    width: 430px;
  }
  .row_match:nth-first(1) {
    padding-top: 0px;
  }
  .row_match {
    padding: 5px 0px;
    border-bottom: 1px solid #f1f1f152;
  }

  .upsale-button {
    color: #00d793;
    position: relative;
    &:before {
      content: "";
      background-color: #00d793;
      height: 2px;
      width: 50px;
      position: absolute;
      top: 23px;
      animation: linear infinite alternate;
      animation-name: run;
      animation-duration: 1.2s;
      -webkit-animation: linear infinite alternate;
      -webkit-animation-name: run;
      -webkit-animation-duration: 1.2s;
    }
  }
  @keyframes run {
    0% {
      left: 0;
      width: 5%;
    }
    50% {
      width: 100%;
    }
    100% {
      right: 0;
      width: 5%;
    }
  }

  .width_match {
    display: flex;
    justify-content: space-between;
    display: -webkit-flex;
    flex-wrap: wrap;
    align-items: center;
    width: calc(100% - 348px);
  }
  .point-title {
    margin-left: 8px;
    width: 108px;
    text-align: center;
    justify-content: space-around;
  }
  .box1,
  .box2 {
    height: 30px;
    padding: 2px 0;
    width: 52px;
    background: var(--i);
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .bl-box.box1,
  .bl-box.box2 {
    border: 1px solid #b9b9b9;
    border-radius: 0;
  }
}
.justify_content_between {
  justify-content: end;
}
/* Add this CSS to your styles file or in a <style> tag in your component */
.custom-swal-background {
  background: linear-gradient(
    45deg,
    #ff9a9e,
    #fad0c4,
    #fad0c4
  ); /* Replace with your desired colors */
  background-size: 400% 400%;
  animation: gradientAnimation 15s ease infinite;
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.bg-color-section {
  background: #04533a !important;
  /* color:white; */
}
.bg-btn {
  margin-top: 20px !important;
}

.btn-for-withdrawal {
  justify-content: space-between;
  display: flex;
}
.bethistory {
  background: #444444 !important;
  padding: 7px;
  border-radius: 1%;
}
.custumform {
  border: none !important;
  color: #fff !important;
  background: #23292e !important;
}
.overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: transparent;
  top: 0px;
  left: 0px;
}

.sidebar.closed {
  transform: translateX(-100%);
}

.sidebar.open {
  transform: translateX(-0%);
}
.sidebar .toggle-button {
  position: absolute;
  top: 10px;
  right: -50px; /* Adjust to fit the width of the sidebar */
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  border-radius: 5px; /* Optional: add border-radius for styling */
}
.toggle-button,
.mobilemenu {
  display: none;
}

.mobilenoneyesno,
.d-sm-none-mobile {
  display: block;
}
.mobile-model-none {
  display: none;
}

.rupeesign {
  position: absolute;
  top: 6px;
  left: 8px;
}
.btn-amount.btn {
  border: none !important;
}
.mobile_button_count {
  display: none;
}
.btn.text-white.bg-color-section{
  font-size: 12px;
}
//   media query start
@media (max-width: 1025px) {
  // .Topnavbar .loginId{
  //   display: none;
  // }
  
  .sidebar.closed {
    transform: translateX(0%);
}
  .account_statement .btn_custom {
    width: 70px;
    background-color: #04533a;
    color: #fff;
    border: 0px;
    outline: none;
    text-align: center;
    display: flex;
    justify-content: center;
    font-size: 13px;
    padding: 5px;
  }
  .accountstmt {
    tbody {
      tr {
        background-color: #fff;
      }
    }
  }
  .btn.text-white.bg-color-section {
    font-size: 10px;
    display: block;
    margin-top: 5px;
    width: 73px;
    padding: 2px 5px;
  }
  .betting_page .round_id h3 {
    font-size: 13px;
  }
  .footer-box {
    width: 90%;
    margin: 0px auto 0px;
  }
  .btn.btn_Transaction:hover,
  .btn.btn_Transaction {
    background: $universal;
    padding: 1px 6px;
    color: #fff;
    font-size: 10px;
  }
  .mobile_d-none-online {
    display: none;
  }
  // .space,
  //     .backbet_design,
  //     .laybet_design {
  //         width: 16%;
  //     }
  .mobile_show_tv {
    display: block;
  }
  .bet-table-body .upsale-button {
    color: #00d793;
    position: relative;
    font-size: 10px;
  }
  .bet-table-body .upsale-button:before {
    top: 16px;
  }
  .matchdate {
    font-size: 10px;
    margin-bottom: 6px;
    color: #9e9e9e;
  }
  .footer-menu ul {
    display: flex;
    flex-wrap: wrap;
    column-gap: 10px;
    li {
      margin: 0 0px;
      padding: 0px 0px;
    }
  }

  .main-button {
    background-color: #02c386;
    border: 0;
    padding: 10px 35px;
    font-size: 20px;
    font-family: "Open Sans";
    border-radius: 3px;
    cursor: pointer;
    color: #222f3e;
    position: relative;
    text-transform: uppercase;

    &:after {
      // background-color: #2de0a7;
      // content: '';
      // display: block;
      // width: 100%;
      // height: 100%;
      // position: absolute;
      // top: 0;
      // left: 0;
      // border-radius: 50%;
      // animation-name: blink;
      // animation-duration: 0.9s;
      // animation-iteration-count: infinite;
      // animation-direction: alternate-reverse;
      // // animation-timing-function: cubic-bezier(1, 0, 0, 1);
      // animation-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
      content: "";
      position: absolute;
      width: 55px;
      height: 55px;
      left: -5px;
      border: 4px dotted #ffffff;
      border-radius: 50%;
      bottom: -5px;
      animation: round 10s linear infinite;
    }
  }

  @keyframes round {
    100% {
      transform: rotate(360deg);
    }
  }
  .mybet_new {
    font-size: 10px;
    width: 100%;
    position: relative;
    white-space: nowrap;
    top: 20px;
    font-weight: 600;
    text-align: center;

    background: linear-gradient(
      to right,
      #fff 20%,
      #ff0 40%,
      #ff0 60%,
      #fff 80%
    );
    background-size: 200% auto;

    color: #000;
    background-clip: text;
    text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    animation: shine 1s linear infinite;
  }
  @keyframes shine {
    to {
      background-position: 200% center;
    }
  }
  .new_font_size {
    font-size: 18px;
    position: relative;
    top: 10px;
  }
  .mobile_button_count {
    z-index: 99;
    display: block;
    width: 45px;
    position: fixed;
    bottom: 13px;
    right: 10px;
    background: #048f63;
    padding: 5px;
    border-radius: 50%;
    text-align: center;
    display: flex;
    height: 45px;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 23px;
    font-family: sans-serif;
    color: #fff;
  }
  .bet-table-header {
    display: none !important;
  }
  .betting_page .bet_details_all .headinglay {
    margin: 3px 0px;
  }
  .user_design {
    background-color: #f1f1f2;
  }
  .icon {
    svg {
      font-size: 12px;
    }
  }
  .fauser {
    background: #8b8b8b;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0;
    position: relative;
    svg {
      color: #fff;
      font-size: 10px;
      padding: 0px;
    }
  }
  .mobile_flex_wallet {
    display: flex;
    gap: 7px;
  }
  // .bet-table-body .point-title {
  //         margin-left: 0px;}

  .display_none_mobile,
  .mobilenonere {
    display: none;
  }
  .font-size-10 {
    font-size: 12px;
  }
  .row.row5 {
    margin: 0px;
    padding: 0px;
  }
  //  .wallet_details{
  //         cursor: pointer;
  //             display: flex;
  //             flex-wrap: wrap;
  //             background: #444;
  //             width: auto;
  //             padding: 0 5px 0 0;
  //             border-radius: 5px;
  //             height: 40px;
  //             .icon {
  //                         background: #04533a;
  //                             display: flex;
  //                             align-items: center;
  //                             justify-content: center;
  //                             margin-right: 10px;
  //                             padding-right: 10px;
  //                             padding-left: 10px;
  //                             color: #ddd;
  //                             height: 35px;
  //                             width: 35px;
  //                             font-size: 30px;
  //                 }
  //  }
  // .width_min.bettingwidth {
  //         width: calc(100% - 303px) !important;
  //     }
  .mobileresponsive {
    .bettingwidth {
      div {
        font-size: 13px;
        margin: 4px 0px;
      }
    }
  }
  .btn-amount {
    border-radius: 5px;
    width: 95px;
    box-shadow: none;
    border: 0px;
    padding: 7px;
    color: #fff;
    border-radius: 5px;
  }
  .margin-top-bottom-topnavbar {
    margin: 5px 0px;
  }
  .modal_width {
    width: 100%;
  }
  .bet-table-body .point-title {
    width: 100px;
  }
  .placebet_section.mobile_betting {
    width: 100%;
  }
  .mobile-model-none {
    display: block;
  }
  .mobilenoneyesno {
    display: none;
  }
  .d-sm-none-mobile {
    display: none;
  }
  .padding_15 {
    padding: 0px;
  }
  .main.bethistory {
    padding: 7px;
  }
  .set_button.bethistory {
    padding: 7px;
  }
  .account_statement {
    .width_100 {
      width: 100%;
    }
    #search {
      .form-group {
        width: 100%;
      }
    }
  }
  .flex-wrap-mobile {
    flex-wrap: wrap;
  }
  .margin-top-40 {
    margin-top: 0px;
    .otherpagegap {
      .logo_design {
        display: none;
      }
      .header_top_new {
        width: 100%;
      }
    }
  }
  .battingpage {
    flex-direction: column;
    .betting_section {
      width: 100%;
    }
  }
  .toggle-button {
    display: block;
    background: transparent;
    border: none;
    color: #fff;
    font-size: 19px;
  }
  .logo_mobile {
    width: 150px;
    margin-left: 100px;
    img {
      width: 100%;
    }
  }
  .sidebar {
    top: 0px !important;
  }
  .header-top {
    display: none;
  }
  .sidebar.closed {
    transform: translateX(0%);
  }

  .sidebar.open {
    transform: translateX(-100%);
  }
  .mobilemenu {
    display: block;
  }
  .content-body {
    margin-left: 0px;
    padding-top: 0px;
  }
  .Topnavbar {
    display: block;
    .upcoming-fixure,
    .news-bar,
    .wallet_details {
      display: none !important;
    }
    .loginId {
      // position: absolute !important;
      // top: 29px;
      // right: 10px;
      // height: auto;
      .icon {
        // display: none;
      }
    }
  }
  .sidebar .toggle-button {
    top: -24px;
    right: -57px;
    background: transparent;
    font-size: 30px;
    padding: 0px;
  }
  .wallet_details {
    .icon {
      svg {
        color: #fff;
      }
    }
    .pts {
      p {
        margin-bottom: 0px;
        color: #fff;
      }
    }
    .btn_Transaction {
      background: $universal;
      padding: 5px 7px;
      font-size: 15px;
      color: #fff;
    }
  }
  .loginId {
    .d-flex {
      gap: 5px;
    }
    div {
      .icon {
        svg {
          color: $white;
        }
      }
      .pts {
        p {
          color: $white;
          margin-bottom: 0px;
          font-size: 12px;
        }
      }
    }
  }
  .bethistory {
    padding: 7px 0px;
    .accountstatement {
      margin-bottom: 10px;
      h5 {
        margin-bottom: 0px;
      }
    }
    .accountstmt thead th {
      font-size: 10px;
    }
    .accountstmt {
      tbody {
        tr {
          td {
            font-size: 10px;
            white-space: nowrap;
          }
        }
      }
    }
  }
  .news-bar {
    display: flex;
    background: #444444;
    border-radius: 5px;
    margin-bottom: 5px;
    font-size: 13px;
    align-items: center;
    .fixure-title.speaker {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
      padding: 5px;
      color: $white;
    }
    marquee {
      color: $white;
    }
  }
  .sidebar {
    top: 0px;
    &.closed {
      .toggle-button {
        top: 0px;
        right: -30px;
        background: #0b0f12;
      }
    }
  }
  .sidebar {
    top: 0px;
    &.open {
      .toggle-button {
        top: 15px;
        right: -42px;
        background: transparent;
      }
    }
  }
}
@media (max-width: 991px) {
  .Topnavbar .loginId{
    display: none;
  }
  .account_statement .btn_custom {
    width: 70px;
    background-color: #04533a;
    color: #fff;
    border: 0px;
    outline: none;
    text-align: center;
    display: flex;
    justify-content: center;
    font-size: 13px;
    padding: 5px;
  }
  .accountstmt {
    tbody {
      tr {
        background-color: #fff;
      }
    }
  }
  .btn.text-white.bg-color-section {
    font-size: 13px;
    display: block;
    margin-top: 5px;
    width: 97px;
    padding: 1px 5px;
  }
  .betting_page .round_id h3 {
    font-size: 13px;
  }
  .footer-box {
    width: 90%;
    margin: 0px auto 0px;
  }
  .btn.btn_Transaction:hover,
  .btn.btn_Transaction {
    background: $universal;
    padding: 1px 6px;
    color: #fff;
    font-size: 10px;
  }
  .mobile_d-none-online {
    display: none;
  }
  // .space,
  //     .backbet_design,
  //     .laybet_design {
  //         width: 16%;
  //     }
  .mobile_show_tv {
    display: block;
  }
  .bet-table-body .upsale-button {
    color: #00d793;
    position: relative;
    font-size: 10px;
  }
  .bet-table-body .upsale-button:before {
    top: 16px;
  }
  .matchdate {
    font-size: 10px;
    margin-bottom: 6px;
    color: #9e9e9e;
  }
  .footer-menu ul {
    display: flex;
    flex-wrap: wrap;
    column-gap: 10px;
    li {
      margin: 0 0px;
      padding: 0px 0px;
    }
  }

  .main-button {
    background-color: #02c386;
    border: 0;
    padding: 10px 35px;
    font-size: 20px;
    font-family: "Open Sans";
    border-radius: 3px;
    cursor: pointer;
    color: #222f3e;
    position: relative;
    text-transform: uppercase;

    &:after {
      // background-color: #2de0a7;
      // content: '';
      // display: block;
      // width: 100%;
      // height: 100%;
      // position: absolute;
      // top: 0;
      // left: 0;
      // border-radius: 50%;
      // animation-name: blink;
      // animation-duration: 0.9s;
      // animation-iteration-count: infinite;
      // animation-direction: alternate-reverse;
      // // animation-timing-function: cubic-bezier(1, 0, 0, 1);
      // animation-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
      content: "";
      position: absolute;
      width: 55px;
      height: 55px;
      left: -5px;
      border: 4px dotted #ffffff;
      border-radius: 50%;
      bottom: -5px;
      animation: round 10s linear infinite;
    }
  }

  @keyframes round {
    100% {
      transform: rotate(360deg);
    }
  }
  .mybet_new {
    font-size: 10px;
    width: 100%;
    position: relative;
    white-space: nowrap;
    top: 20px;
    font-weight: 600;
    text-align: center;

    background: linear-gradient(
      to right,
      #fff 20%,
      #ff0 40%,
      #ff0 60%,
      #fff 80%
    );
    background-size: 200% auto;

    color: #000;
    background-clip: text;
    text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    animation: shine 1s linear infinite;
  }
  @keyframes shine {
    to {
      background-position: 200% center;
    }
  }
  .new_font_size {
    font-size: 18px;
    position: relative;
    top: 10px;
  }
  .mobile_button_count {
    z-index: 99;
    display: block;
    width: 45px;
    position: fixed;
    bottom: 13px;
    right: 10px;
    background: #048f63;
    padding: 5px;
    border-radius: 50%;
    text-align: center;
    display: flex;
    height: 45px;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 23px;
    font-family: sans-serif;
    color: #fff;
  }
  .bet-table-header {
    display: none !important;
  }
  .betting_page .bet_details_all .headinglay {
    margin: 3px 0px;
  }
  .user_design {
    background-color: #f1f1f2;
  }
  .icon {
    svg {
      font-size: 12px;
    }
  }
  .fauser {
    background: #8b8b8b;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0;
    position: relative;
    svg {
      color: #fff;
      font-size: 10px;
      padding: 0px;
    }
  }
  .mobile_flex_wallet {
    display: flex;
    gap: 7px;
  }
  // .bet-table-body .point-title {
  //         margin-left: 0px;}

  .display_none_mobile,
  .mobilenonere {
    display: none;
  }
  .font-size-10 {
    font-size: 12px;
  }
  .row.row5 {
    margin: 0px;
    padding: 0px;
  }
  //  .wallet_details{
  //         cursor: pointer;
  //             display: flex;
  //             flex-wrap: wrap;
  //             background: #444;
  //             width: auto;
  //             padding: 0 5px 0 0;
  //             border-radius: 5px;
  //             height: 40px;
  //             .icon {
  //                         background: #04533a;
  //                             display: flex;
  //                             align-items: center;
  //                             justify-content: center;
  //                             margin-right: 10px;
  //                             padding-right: 10px;
  //                             padding-left: 10px;
  //                             color: #ddd;
  //                             height: 35px;
  //                             width: 35px;
  //                             font-size: 30px;
  //                 }
  //  }
  // .width_min.bettingwidth {
  //         width: calc(100% - 303px) !important;
  //     }
  .mobileresponsive {
    .bettingwidth {
      div {
        font-size: 13px;
        margin: 4px 0px;
      }
    }
  }
  .btn-amount {
    border-radius: 5px;
    width: 95px;
    box-shadow: none;
    border: 0px;
    padding: 7px;
    color: #fff;
    border-radius: 5px;
  }
  .margin-top-bottom-topnavbar {
    margin: 5px 0px;
  }
  .modal_width {
    width: 100%;
  }
  .bet-table-body .point-title {
    width: 100px;
  }
  .placebet_section.mobile_betting {
    width: 100%;
  }
  .mobile-model-none {
    display: block;
  }
  .mobilenoneyesno {
    display: none;
  }
  .d-sm-none-mobile {
    display: none;
  }
  .padding_15 {
    padding: 0px;
  }
  .main.bethistory {
    padding: 7px;
  }
  .set_button.bethistory {
    padding: 7px;
  }
  .account_statement {
    .width_100 {
      width: 100%;
    }
    #search {
      .form-group {
        width: 100%;
      }
    }
  }
  .flex-wrap-mobile {
    flex-wrap: wrap;
  }
  .margin-top-40 {
    margin-top: 0px;
    .otherpagegap {
      .logo_design {
        display: none;
      }
      .header_top_new {
        width: 100%;
      }
    }
  }
  .battingpage {
    flex-direction: column;
    .betting_section {
      width: 100%;
    }
  }
  .toggle-button {
    display: block;
    background: transparent;
    border: none;
    color: #fff;
    font-size: 19px;
  }
  .logo_mobile {
    width: 150px;
    margin-left: 100px;
    img {
      width: 100%;
    }
  }
  .sidebar {
    top: 0px !important;
  }
  .header-top {
    display: none;
  }
  .sidebar.closed {
    transform: translateX(0%);
  }

  .sidebar.open {
    transform: translateX(-100%);
  }
  .mobilemenu {
    display: block;
  }
  .content-body {
    margin-left: 0px;
    padding-top: 0px;
  }
  .Topnavbar {
    display: block;
    .upcoming-fixure,
    .news-bar,
    .wallet_details {
      display: none !important;
    }
    .loginId {
      // position: absolute !important;
      // top: 29px;
      // right: 10px;
      // height: auto;
      .icon {
        // display: none;
      }
    }
  }
  .sidebar .toggle-button {
    top: -24px;
    right: -57px;
    background: transparent;
    font-size: 30px;
    padding: 0px;
  }
  .wallet_details {
    .icon {
      svg {
        color: #fff;
      }
    }
    .pts {
      p {
        margin-bottom: 0px;
        color: #fff;
      }
    }
    .btn_Transaction {
      background: $universal;
      padding: 5px 7px;
      font-size: 15px;
      color: #fff;
    }
  }
  .loginId {
    .d-flex {
      gap: 5px;
    }
    div {
      .icon {
        svg {
          color: $white;
        }
      }
      .pts {
        p {
          color: $white;
          margin-bottom: 0px;
          font-size: 12px;
        }
      }
    }
  }
  .bethistory {
    padding: 7px 0px;
    .accountstatement {
      margin-bottom: 10px;
      h5 {
        margin-bottom: 0px;
      }
    }
    .accountstmt thead th {
      font-size: 10px;
    }
    .accountstmt {
      tbody {
        tr {
          td {
            font-size: 10px;
            white-space: nowrap;
          }
        }
      }
    }
  }
  .news-bar {
    display: flex;
    background: #444444;
    border-radius: 5px;
    margin-bottom: 5px;
    font-size: 13px;
    align-items: center;
    .fixure-title.speaker {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
      padding: 5px;
      color: $white;
    }
    marquee {
      color: $white;
    }
  }
  .sidebar {
    top: 0px;
    &.closed {
      .toggle-button {
        top: 0px;
        right: -30px;
        background: #0b0f12;
      }
    }
  }
  .sidebar {
    top: 0px;
    &.open {
      .toggle-button {
        top: 15px;
        right: -42px;
        background: transparent;
      }
    }
  }
}

@media (max-width: 767px) {
  .bet-table-body .box1,
  .bet-table-body .box2 {
    height: 30px;
    padding: 2px 0;
    width: 84px;
    background: var(--i);
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .justify_content_between {
    justify-content: start;
  }
  .row_match {
    .d-flex.justify-content-between {
      flex-direction: column;
      .width_match {
        width: 100%;
        padding: 0px 6px;
      }
    }
  }
  .wallet_details .btn_Transaction {
    padding: 2px 4px;
    color: #fff;
    font-size: 9px;
  }
  .loginId {
    .pts {
      font-size: 9px;
      p {
        margin-bottom: 0px;
      }
    }
  }
  .logo_mobile {
    width: 76px;
    margin-left: 0px;
  }
  .wallet_details .pts {
    p {
      font-size: 12px;
    }
  }
}
#youSend {
  background: transparent;
  color: #fff !important;
 
}
/* Responsive styles */
@media (max-width: 1200px) {
  .terms-container {
    padding: 15px;
    max-width: 90%;
  }

  .terms-heading {
    font-size: 26px;
  }

  .terms-section h2 {
    font-size: 20px;
  }

  .terms-paragraph,
  .terms-section p {
    font-size: 15px;
  }
}

@media (max-width: 992px) {
  .terms-container {
    padding: 10px;
    max-width: 95%;
  }

  .terms-heading {
    font-size: 24px;
  }

  .terms-section h2 {
    font-size: 18px;
  }

  .terms-paragraph,
  .terms-section p {
    font-size: 14px;
  }
}

@media (max-width: 768px) {
  .bet-table-body .point-title {
    width: 100%;
  }
  .bet-table-body .box1,
  .bet-table-body .box2 {
    height: 23px;
    padding: 2px 0;
    width: 100%;
    background: var(--i);
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 13px;
  }
  .terms-container {
    padding: 10px;
    margin: 10px;
  }

  .terms-heading {
    font-size: 22px;
  }

  .terms-section h2 {
    font-size: 16px;
  }

  .terms-paragraph,
  .terms-section p {
    font-size: 13px;
  }
}

@media (max-width: 576px) {
  .bet-table-body {
    padding: 0px 0px;
  }
  .mobile_width {
    justify-content: space-between;
    width: 100%;
  }
  .mobilewrap {
    flex-direction: column;
    padding: 0px 5px;
  }
  .btn-amount {
    width: 80px;
}
  .matchname {
    font-size: 12px;
  }

  .terms-container {
    padding: 8px;
    margin: 8px;
  }

  .terms-heading {
    font-size: 20px;
  }

  .terms-section h2 {
    font-size: 14px;
  }

  .terms-paragraph,
  .terms-section p {
    font-size: 12px;
  }
}
