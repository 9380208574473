  /*
    Table of Contents

    Name                 : Virtual Betting Game
    Author               : Rohit   

*/
@import url("https://fonts.googleapis.com/css?family=Roboto:400,500,700");

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  color: #3d4465;
}
.ballbyballsuspend .suspended{
  right: auto;
}
h1 {
  font-size: 2.5rem;
  /* 40px */
}

h2 {
  font-size: 2rem;
  /* 32px */
}

h3 {
  font-size: 1.75rem;
  /* 28px */
}

h4 {
  font-size: 1.5rem;
}

h5 {
  font-size: 1.25rem;
  /* 20px */
}

h6 {
  font-size: 1rem;
  /* 16px */
}

.gradient_one {
  background-image: linear-gradient(to right, rgba(186, 1, 181, 0.85) 0%, rgba(103, 25, 255, 0.85) 100%);
}

.gradient-1 {
  background: #f0a907;
  background: -moz-linear-gradient(top, #f0a907 0%, #f53c79 100%);
  background: -webkit-linear-gradient(top, #f0a907 0%, #f53c79 100%);
  background: linear-gradient(to bottom, #f0a907 0%, #f53c79 100%) !important;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f0a907', endColorstr='#f53c79', GradientType=0);
}

.gradient-2 {
  background: #4dedf5;
  background: -moz-linear-gradient(top, #4dedf5 0%, #480ceb 100%);
  background: -webkit-linear-gradient(top, #4dedf5 0%, #480ceb 100%);
  background: linear-gradient(to bottom, #4dedf5 0%, #480ceb 100%) !important;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#4dedf5', endColorstr='#480ceb', GradientType=0);
}

.gradient-3,
.morris-hover {
  background: #51f5ae;
  background: -moz-linear-gradient(top, #51f5ae 0%, #3fbcda 100%);
  background: -webkit-linear-gradient(top, #51f5ae 0%, #3fbcda 100%);
  background: linear-gradient(to bottom, #51f5ae 0%, #3fbcda 100%) !important;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#51f5ae', endColorstr='#3fbcda', GradientType=0);
}

.gradient-4 {
  background: #f25521;
  background: -moz-linear-gradient(left, #f25521 0%, #f9c70a 100%);
  background: -webkit-linear-gradient(left, #f25521 0%, #f9c70a 100%);
  background: linear-gradient(to right, #f25521 0%, #f9c70a 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f25521', endColorstr='#f9c70a', GradientType=1);
}

.gradient-5 {
  background: #f53c79;
  background: -moz-linear-gradient(left, #f53c79 0%, #f0a907 100%);
  background: -webkit-linear-gradient(left, #f53c79 0%, #f0a907 100%);
  background: linear-gradient(to right, #f53c79 0%, #f0a907 100%) !important;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f53c79', endColorstr='#f0a907', GradientType=1);
}

.gradient-6 {
  background: #36b9d8;
  background: -moz-linear-gradient(left, #36b9d8 0%, #4bffa2 100%);
  background: -webkit-linear-gradient(left, #36b9d8 0%, #4bffa2 100%);
  background: linear-gradient(to right, #36b9d8 0%, #4bffa2 100%) !important;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#36b9d8', endColorstr='#4bffa2', GradientType=1);
}

.gradient-7 {
  background: #4400eb;
  background: -moz-linear-gradient(left, #4400eb 0%, #44e7f5 100%);
  background: -webkit-linear-gradient(left, #4400eb 0%, #44e7f5 100%);
  background: linear-gradient(to right, #4400eb 0%, #44e7f5 100%) !important;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#4400eb', endColorstr='#44e7f5', GradientType=1);
}

.gradient-8 {
  background: #F7B00F;
  background: -moz-linear-gradient(top, #F7B00F 0%, #F25521 100%);
  background: -webkit-linear-gradient(top, #F7B00F 0%, #F25521 100%);
  background: linear-gradient(to bottom, #F7B00F 0%, #F25521 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#F7B00F', endColorstr='#F25521', GradientType=1);
}

.gradient-9,
.datepicker.datepicker-dropdown td.day:hover,
.datepicker.datepicker-dropdown th.next:hover,
.datepicker.datepicker-dropdown th.prev:hover,
.datepicker table tr td.selected,
.datepicker table tr td.active,
.datepicker table tr td.today,
.datepicker table tr td.today:hover,
.datepicker table tr td.today.disabled,
.datepicker table tr td.today.disabled:hover {
  background: #f31e7a !important;
  background: -moz-linear-gradient(left, #f31e7a 0%, #fd712c 100%);
  background: -webkit-linear-gradient(left, #f31e7a 0%, #fd712c 100%);
  background: linear-gradient(to right, #f31e7a 0%, #fd712c 100%) !important;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f31e7a', endColorstr='#fd712c', GradientType=1);
}

.gradient-10 {
  background: #f25521 !important;
  background: -moz-linear-gradient(left, #f25521 0%, #f9c70a 100%);
  background: -webkit-linear-gradient(left, #f25521 0%, #f9c70a 100%);
  background: linear-gradient(to top, #f25521 0%, #f9c70a 100%) !important;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f25521', endColorstr='#f9c70a', GradientType=1);
}

.gradient-11 {
  background: #3398fb;
  background: -moz-linear-gradient(left, #3398fb 0%, #8553ee 100%);
  background: -webkit-linear-gradient(left, #3398fb 0%, #8553ee 100%);
  background: linear-gradient(to right, #3398fb 0%, #8553ee 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#3398fb', endColorstr='#8553ee', GradientType=1);
}

.gradient-12 {
  background: #36e1b4;
  background: -moz-linear-gradient(left, #36e1b4 0%, #11cae7 100%);
  background: -webkit-linear-gradient(left, #36e1b4 0%, #11cae7 100%);
  background: linear-gradient(to right, #36e1b4 0%, #11cae7 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#36e1b4', endColorstr='#11cae7', GradientType=1);
}

.gradient-13 {
  background: #ffbf31;
  background: -moz-linear-gradient(left, #ffbf31 0%, #ff890e 100%);
  background: -webkit-linear-gradient(left, #ffbf31 0%, #ff890e 100%);
  background: linear-gradient(to right, #ffbf31 0%, #ff890e 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffbf31', endColorstr='#ff890e', GradientType=1);
}

.gradient-14 {
  background: #23bdb8;
  background: -moz-linear-gradient(-45deg, #23bdb8 0%, #43e794 100%);
  background: -webkit-linear-gradient(-45deg, #23bdb8 0%, #43e794 100%);
  background: linear-gradient(135deg, #23bdb8 0%, #43e794 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#23bdb8', endColorstr='#43e794', GradientType=1);
}

.gradient-15 {
  background: #9a56ff;
  background: -moz-linear-gradient(-45deg, #9a56ff 0%, #e36cd9 100%);
  background: -webkit-linear-gradient(-45deg, #9a56ff 0%, #e36cd9 100%);
  background: linear-gradient(135deg, #9a56ff 0%, #e36cd9 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#9a56ff', endColorstr='#e36cd9', GradientType=1);
}

.gradient-16 {
  background: #f48665;
  background: -moz-linear-gradient(-45deg, #f48665 0%, #fda23f 100%);
  background: -webkit-linear-gradient(-45deg, #f48665 0%, #fda23f 100%);
  background: linear-gradient(135deg, #f48665 0%, #fda23f 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f48665', endColorstr='#fda23f', GradientType=1);
}

.gradient-17 {
  background: #e36cd9;
  background: -moz-linear-gradient(-45deg, #e36cd9 0%, #fe60ae 100%);
  background: -webkit-linear-gradient(-45deg, #e36cd9 0%, #fe60ae 100%);
  background: linear-gradient(135deg, #e36cd9 0%, #fe60ae 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#e36cd9', endColorstr='#fe60ae', GradientType=1);
}

.gradient-18 {
  background: #a15cff;
  background: -moz-linear-gradient(left, #a15cff 0%, #ce82fd 100%);
  background: -webkit-linear-gradient(left, #a15cff 0%, #ce82fd 100%);
  background: linear-gradient(to right, #a15cff 0%, #ce82fd 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#a15cff', endColorstr='#ce82fd', GradientType=1);
}

:root {
  --blue: #5e72e4;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #EE3232;
  --orange: #fd7e14;
  --yellow: #FFFA6F;
  --green: #297F00;
  --teal: #20c997;
  --cyan: #3065D0;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #593bdb;
  --secondary: #673BB7;
  --success: #7ED321;
  --info: #50E3C2;
  --warning: #FFAA16;
  --danger: #FF1616;
  --light: #f8f9fa;
  --dark: #454545;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --breakpoint-xxl: 1440;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}


